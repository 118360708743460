.cards1 {
    padding-left: 10px;
    padding-top: 10px;
    padding-bottom: 10px;
    padding-right: 10px;
    color: #ffffff;
    /* background-color: #212529; */
    background-image: url(../../../public/images/banner-web-Nankang.webp);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    font-family: 'Gill Sans', Calibri;
}

.video-mapa {
    padding-top: 80px;
    padding-bottom: 40px;
    max-height: 100%;
    max-width: auto;
    font-family: 'Gill Sans', Calibri;
}

.comercios {
    font-size: 30px;
    padding-top: 120px;
    font-family: 'Gill Sans', Calibri;
}

.svg-container {
    /* background-color: #212529; */
    width: 90%;
    height: 90%;
}

.svg {
    width: 100%;
    height: 100%;
}

#mapa path {
    fill: #E60012;
    stroke: #ffffff;
    stroke-width: 2px;
}

#mapa :hover {
    fill: #000;
}

.row {
    margin-left: -10px !important;
    margin-right: -10px !important;
}
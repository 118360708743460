.description-zone {
    background-color: #000 !important;
}

h6{
    color: #E60012;
    text-align: center;
    padding-top: 5%;
}

.description {
    color: #fff !important;
    text-align: center;
    padding-bottom: 3%;
}

.front {
    -webkit-transform: rotateY(0deg);
    -ms-transform: rotateY(0deg);
    background: transparent;
    z-index: 2;
    border: 2px solid rgba(27, 27, 27, 1);
    padding: 48px 45px 45px;
    width: 100%;
}

.icon_box_1 {
    display: inline-block;
    position: relative;
    width: 100%;
    background: transparent;
    -webkit-perspective: 1000;
    -moz-perspective: 1000;
    -ms-perspective: 1000;
    perspective: 1000;
    -ms-transform: perspective(1000px);
    -moz-transform-style: preserve-3d;
    -ms-transform-style: preserve-3d;
    transition: all ease 400ms;
    -webkit-transition: all ease 400ms;
    -moz-transition: all ease 400ms;
    padding-bottom: 20% !important;
}

.icon_box_1 .front-header {
    font-size: 16px;
    line-height: 20px;
    font-weight: 400;
    text-transform: uppercase;
    color: #aaaaaa;
    margin: 0;
    transition: all ease 400ms;
    -moz-transition: all ease 400ms;
    -webkit-transition: all ease 400ms;
}

.icon_box_1 .front-icon {
    font-size: 64px;
    line-height: 1;
    color: #aaaaaa;
    display: block;
    margin-bottom: 17px;

}

.front {
    -webkit-backface-visibility: hidden;
    -moz-backface-visibility: hidden;
    -ms-backface-visibility: hidden;
    backface-visibility: hidden;
    -webkit-transition: 0.6s;
    -webkit-transform-style: preserve-3d;
    -webkit-transform: rotateY(0deg);
    -moz-transition: 0.6s;
    -moz-transform-style: preserve-3d;
    -moz-transform: rotateY(0deg);
    -o-transition: 0.6s;
    -o-transform-style: preserve-3d;
    -o-transform: rotateY(0deg);
    -ms-transition: 0.6s;
    -ms-transform-style: preserve-3d;
    -ms-transform: rotateY(0deg);
    transition: 0.6s;
    transform-style: preserve-3d;
    transform: rotateY(0deg);
    position: absolute;
    top: 0;
    left: 0;
}

.custom_column .col-lg-3 {
    width: 20%;
}
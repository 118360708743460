.advantage-mark {
    background-image: url(../../../../public/images/banner-trayectory.webp);
    background-repeat: no-repeat;
    background-size: cover;
    margin: 0;
    padding: 0;
    font-family: "Raleway", sans-serif;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

html,
.advantage-mark {
    height: 100%;
}

/* .text-center {
    text-align: center !important;
} */

.size-count {
    font-size: 80px !important;
    color: #E60012;
}

.mark-trayectory {
    color: #fff;
    padding-top: 30px;
    padding-right: 50%;
}

.rasgos {
    color: #fff;
    padding-right: 50%;

}

.divisor {
    border: 5px solid #E60012 !important;
    opacity: 10 !important;
    padding-right: 70% !important;
    width: 20% !important;
}

.advantage-mark .count-up-wrapper {
    text-align: center;
    font-size: 7rem;
    color: #fff;
    height: 100%;
    width: 100%;
    margin: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
}

.advantage-mark .count-up-wrapper .additional-info {
    font-size: 2rem;
    position: absolute;
    top: 0;
    left: 0;
    padding: 5px 5px 5px 15px;
}

/* .advantage-mark .count-up-wrapper {
    color: #fff;
} */

.advantage-mark .end-container {
    position: fixed;
    width: 100%;
    height: 100%;
    color: #fff;
    z-index: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.3rem;
    opacity: 0;
    visibility: hidden;
    transition: all 0.8s ease-in-out;
    transform: translateY(-50px);
}

.advantage-mark .end-container.active {
    opacity: 1;
    visibility: visible;
    transform: translateY(0px);
}

.additional-info {
    text-align: center !important;
}

.grilla {
    padding-top: 7%;
    padding-bottom: 3%;
}

#count-up-container-top {
    color: #E60012;
}

#count-up-container-middler {
    color: #E60012;
}

#count-up-container-bottom {
    color: #E60012;
}

.year {
    color: #E60012;
}
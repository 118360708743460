@import url("https://fonts.googleapis.com/css2?family=Exo+2:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Noto+Sans+TC:wght@100;300;400;500;700;900&display=swap");

a,
.media_bar .container:nth-child(2) li,
.footer_nav a .font_and,
.left_menu li.left_menu_drop>a::after,
.skew_btn .skew_btn_arrow,
.skew_btn .skew_btn_download,
.skew_btn::after,
.hr_benefit_ul .bnft_box,
.bnft_black_icon,
.hr_map_option li,
.poster_ul .poster_box .poster_img .poster_hover,
.poster_ul .poster_name .poster_number,
.poster_ul .poster_name .poster_year,
.kn_detail_01 .poplist_btn,
.kn_detail_02 .tire_data_main .tire_data_bg,
.kn_detail_03 .btn_group .skew_btn .skew_btn_arrow,
.tyre_rec_btns>button,
.tyre_rec_btns>button::after,
.tyre_rec_ul li .weird_shape_bg,
.pro_brand .txt_part .tit span,
.pro_brand .txt_part .tit .line,
.pro_page_nav .layer_2_show .view a,
.pro_detail_top .left_pic .show .enlarge,
.pro_detail_top .left_pic .show .download,
.pro_detail_top .left_pic .small_ul .bg,
.more_link span:not(.bg),
.more_link span.bg,
.banner .left_btn .bg,
.banner .right_btn .bg,
.banner .left_btn .icon,
.banner .right_btn .icon,
.banner .search_box .tab .item>.view,
.form .btn_black .bg,
.index_csr .tab .item .line span,
.index_pro>.box .item .pic,
.index_pro>.box .item h5,
.index_pro>.box .item h5 .line span,
.index_Knowledg .tire_area .tire_part>.show .pic,
.index_store .tab .item>.view>.bg,
.index_store .tab .item>.view .cart .pic,
.index_store .city>.show ul li>.view>.bg,
.news_page_nav .layer_2_show .view a {


    @import url("https://fonts.googleapis.com/css2?family=Exo+2:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
    -moz-transition: all 0.3s cubic-bezier(0.04, 0.45, 0.2, 0.96);
    -o-transition: all 0.3s cubic-bezier(0.04, 0.45, 0.2, 0.96);
    -webkit-transition: all 0.3s cubic-bezier(0.04, 0.45, 0.2, 0.96);
    transition: all 0.3s cubic-bezier(0.04, 0.45, 0.2, 0.96)
}

@media only screen and (min-width: 992px) {

    a:hover,
    .media_bar .container:nth-child(2) li:hover,
    .footer_nav a .font_and:hover,
    .left_menu li.left_menu_drop>a:hover::after,
    .skew_btn .skew_btn_arrow:hover,
    .skew_btn .skew_btn_download:hover,
    .skew_btn:hover::after,
    .hr_benefit_ul .bnft_box:hover,
    .bnft_black_icon:hover,
    .hr_map_option li:hover,
    .poster_ul .poster_box .poster_img .poster_hover:hover,
    .poster_ul .poster_name .poster_number:hover,
    .poster_ul .poster_name .poster_year:hover,
    .kn_detail_01 .poplist_btn:hover,
    .kn_detail_02 .tire_data_main .tire_data_bg:hover,
    .kn_detail_03 .btn_group .skew_btn .skew_btn_arrow:hover,
    .tyre_rec_btns>button:hover,
    .tyre_rec_btns>button:hover::after,
    .tyre_rec_ul li .weird_shape_bg:hover,
    .pro_brand .txt_part .tit span:hover,
    .pro_brand .txt_part .tit .line:hover,
    .pro_page_nav .layer_2_show .view a:hover,
    .pro_detail_top .left_pic .show .enlarge:hover,
    .pro_detail_top .left_pic .show .download:hover,
    .pro_detail_top .left_pic .small_ul .bg:hover,
    .more_link span:hover:not(.bg),
    .more_link span.bg:hover,
    .banner .left_btn .bg:hover,
    .banner .right_btn .bg:hover,
    .banner .left_btn .icon:hover,
    .banner .right_btn .icon:hover,
    .banner .search_box .tab .item>.view:hover,
    .form .btn_black .bg:hover,
    .index_csr .tab .item .line span:hover,
    .index_pro>.box .item .pic:hover,
    .index_pro>.box .item h5:hover,
    .index_pro>.box .item h5 .line span:hover,
    .index_Knowledg .tire_area .tire_part>.show .pic:hover,
    .index_store .tab .item>.view>.bg:hover,
    .index_store .tab .item>.view .cart .pic:hover,
    .index_store .city>.show ul li>.view>.bg:hover,
    .news_page_nav .layer_2_show .view a:hover {
        -moz-transition: all 0.3s cubic-bezier(0.04, 0.45, 0.2, 0.96);
        -o-transition: all 0.3s cubic-bezier(0.04, 0.45, 0.2, 0.96);
        -webkit-transition: all 0.3s cubic-bezier(0.04, 0.45, 0.2, 0.96);
        transition: all 0.3s cubic-bezier(0.04, 0.45, 0.2, 0.96)
    }
}

/* Banner */
.banner_box {
    height: 320px;
    position: relative;
}

.banner_box .banner_red_bg {
    position: absolute;
    bottom: -10px;
    right: 0;
    width: 1270px;
    height: 330px;
    background-image: url(../../../public/images/banner_red_bg.webp);
    background-position: center right;
    background-repeat: no-repeat;
    background-size: cover;
    z-index: 1;
}

.banner_box .banner_pic.banner_pic_knowledge_detail {
    background-image: url(../../../public/images/banner_knowledge_detail.webp);
}

.banner_box .banner_pic {
    position: absolute;
    top: 0;
    right: 145px;
    width: 100%;
    height: 320px;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    z-index: 2;
}

.banner_box .banner_black_cover {
    position: absolute;
    top: 0;
    right: 145px;
    width: 100%;
    height: 320px;
    background-color: rgba(0, 0, 0, 0.3);
    z-index: 2;
}

.main {
    max-width: 1240px;
    margin-left: auto;
    margin-right: auto;
}

.banner_title {
    position: relative;
    z-index: 10;
    color: #ffffff;
    padding-top: 140px;
    text-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);
}

.title_l {
    font-size: 42px;
    font-weight: bold;
    line-height: 60px;
}

.banner_title .red_line {
    display: block;
    width: 120px;
    height: 3px;
    background-color: #E60012;
    margin-right: 20px;
    margin-top: 18px;
}

.banner_title h3 {
    min-height: 70px;
}

/* Marca de talón */
.heel-mark {
    background-color: #111;
}

.heel-mark {
    width: 100% !important;
    background-size: cover !important;
    background-position: 0 0 !important;
    background-repeat: no-repeat !important;
    padding: 0 !important;
    margin: 0 !important;
}

.main {
    max-width: 1240px !important;
    margin-left: auto !important;
    margin-right: auto !important;
}

.heel-mark-detail.spacing {
    padding-top: 90px !important;
    padding-bottom: 100px !important;
}

.double_line_title.white {
    color: #ffffff !important;
}

.title_m {
    font-size: 32px !important;
    font-weight: bold !important;
    line-height: 40px !important;
}

.marb_50 {
    margin-bottom: 50px !important;
}

.double_line_title {
    padding-left: 36px !important;
    position: relative !important;
}

.row {
    margin-left: -10px !important;
    margin-right: -10px !important;
}

.heel-mark-detail .tire_data_main {
    width: 60% !important;
    margin: 0 auto !important;
    margin-bottom: 75px !important;
    position: relative !important;
}

.heel-mark .tire_data_main .tire_data_pic {
    width: 100% !important;
    height: 0 !important;
    padding-bottom: 100% !important;
    background-image: url(../../../public/images/about-tire.webp) !important;
    background-position: 50% 50% !important;
    background-repeat: no-repeat !important;
    background-size: contain !important;
    position: relative !important;
    z-index: 2 !important;
}

.heel-mark .tire_data_main .tire_data_bg {
    width: 125% !important;
    height: 0 !important;
    padding-bottom: 125% !important;
    background-image: url(../../../public/images/light-tire.webp) !important;
    background-position: 50% 50% !important;
    background-repeat: no-repeat !important;
    background-size: contain !important;
    position: absolute !important;
    z-index: 1 !important;
    bottom: -10% !important;
    left: -10% !important;
    right: -10% !important;
    opacity: 0 !important;
    transition: all 0.3s cubic-bezier(0.04, 0.45, 0.2, 0.96) !important;

}

.tire_data_main:hover .tire_data_bg {
    opacity: 1 !important;
}

.double_line_title::before {
    content: "";
    position: absolute;
    top: 4px;
    left: 18px;
    width: 3px;
    height: 35px;
    transform: rotate(25deg);
    background-color: #E60012;
}

.double_line_title::after {
    content: "";
    position: absolute;
    top: 4px;
    left: 8px;
    width: 3px;
    height: 35px;
    transform: rotate(25deg);
    background-color: #E60012;
}

.tire-about {
    letter-spacing: .05em;
    color: #333;
    font-size: 18px;
    line-height: 35px;
}

@media only screen and (max-width: 1240px) {
    .main {
        padding-left: 20px;
        padding-right: 20px;
    }
}

@media only screen and (min-width: 768px) {
    .d-pad-flex {
        display: flex;
    }
}
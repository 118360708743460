.lema {
    background-image: url(../../../../public/images/banner_shop.webp);
    background-repeat: no-repeat;
    background-size: cover;

}

.frase {
    text-align: center;
    color: #fff;
    font-size: 90px;
    font-style: italic;
    padding-top: 10%;
    padding-bottom: 10%;
}

.divisor-lema {
    border: 1px solid #E60012 !important;
    opacity: 10 !important;
}
.navbar-light .navbar-nav .navlink {
    color: #fff !important;
    font-size: 18px !important;

}

.navbar-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 80px;
    max-width: 1500px;
  }

.navbar-nav {
    flex-direction: row !important;
}

.nav-menu {
    padding: 8px !important;
}

.nav-link {
    padding-right: 20px !important;
    padding-top: 30px;
    font-size: 20px;
} 

.navbar-light .navbar-brand {
    color: #fff !important;
    font-size: 30px !important;
}

.navbar {
    --bs-navbar-padding-y: 1.5rem !important;
}

.bg-dark {
    background-color: #000 !important;
}

.navlink.active {
    color: #E60012 !important;
}

.menu-icon {
    display: none;
}

.fa-bars {
    color: #fff !important;
}

@media screen and (max-width: 960px) {
    .NavbarItems {
        position: relative;
    }

    .navbar-nav {
        flex-direction: column !important;
        margin:.7rem auto!important ;
        text-align: center !important;
        justify-content: space-between !important;
    }

    .nav-menu {
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 90vh;
        position: absolute;
        top: 80px;
        left: -100%;
        opacity: 1;
        justify-content: start;
        transition: all 0.5s ease;
    }

    .nav-menu.active {
        background: #000;
        left: 0;
        opacity: 1;
        transition: all 0.5s ease;
        z-index: 1;
    }

    .nav-link {
        text-align: center;
        padding: 2rem;
        width: 100%;
        display: table;
        font-size: 25px;
    }

    .navbar-logo {
        position: absolute;
        top: 0;
        left: 0;
        transform: translate(25%, 50%);
    }

    .menu-icon {
        display: block;
        position: absolute;
        top: 0;
        right: 0;
        transform: translate(-100%, 60%);
        font-size: 1.8rem;
        cursor: pointer;
    }

    .fa-times {
        color: #fff;
        font-size: 2rem;
    }

    .nav-links-mobile {
        display: block;
        text-align: center;
        margin: 2rem auto;
        border-radius: 4px;
        width: 80%;
        text-decoration: none;
        font-size: 1.5rem;
        background-color: transparent;
        color: #000;
        padding: 14px 20px;
        border: 1px solid #fff;
        transition: all 0.3s ease-out;
    }
}
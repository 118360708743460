.main-contacts {
    background-image: url("../../../public/images/fondo-contact.webp");
    background-repeat: no-repeat;
    background-size: cover;
    padding-top: 250px;
    padding-bottom: 100px;
}

.link-contacts {
    margin-top: 50% !important;
    font-size: 70px !important;
    border-radius: 10px;
    transition: all 0.1s ease-out;
    color: #fff !important;
}

.link-contacts:hover {
    border-color: #E60012;
    text-decoration: none;
}

.subtitle {
    color: #fff !important;
    font-size: 40px !important;
    text-align: center !important;
}

.logo-fondo {
    position: absolute;
    width: 100%;
    height: 100%;
    background-size: cover;
    z-index: 1;
    opacity: 0.5;
}

.ubic {
    position: relative;
    padding-bottom: 56.25%;
    padding-top: 30px;
    height: 0;
    overflow: hidden;
}

.ubic iframe,
.ubic object,
.ubic embed {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}


.text-center {
    color: #fff;
    position: relative;
    z-index: 2;
}

.contact {
    color: #000;
    margin-top: 10px;
    padding: 15px;
    position: relative;
    transform: scale(1);
    background-image: none;
    transition: 1s;
    text-align: center;
    position: relative;
    z-index: 3;
}

.contact::before {
    content: '';
    position: absolute;
    /* background: #28B463; */
    visibility: hidden;
    height: 3px;
    width: 100%;
    bottom: 0;
    left: 0;
    transform: scaleX(0);
    transition: .7s;
}

.contact:hover:before {
    visibility: visible;
    transform: scaleX(1);
}

/* .contact a:hover {
    color: #28B463 !important;
} */

.contact:hover {
    transform: scale(1.05);
}
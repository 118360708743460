h4 {
    color: #fff !important;
    margin-right: 50% !important;
    font-size: 20px !important;
    text-align: left !important;
    margin: auto !important;
}

.title-principal {
    font-size: 50px !important;
    margin-right: 50% !important;
    margin-bottom: 50px !important;
    color: #fff;
    text-align: left !important;
    margin: auto !important;
}

.content-image {
    position: relative;
    padding-bottom: 56.25%;
    padding-top: 30px;
    height: 0;
    overflow: hidden;
}

.content-image img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

/* .carousel-control-next-icon:after {
    content: '>';
    font-size: 55px;
    color: #E60012;

}

.carousel-control-prev-icon:after {
    content: '<';
    font-size: 55px;
    color: #E60012;

} */